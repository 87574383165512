export default [
  {
    path: '/factCarousels',
    name: 'factCarousels',
    component: () => import('@/views/fact-carousel/Index.vue'),
    meta: {
      pageTitle: 'Quản lý carousel',
      breadcrumb: [
        {
          text: 'Hệ thống',
          active: false,
        },
        {
          text: 'Quản lý carousel',
          active: true,
        },
      ],
      action: 'READ',
      resource: 'CAROUSELS',
    },
  },
]
