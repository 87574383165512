import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    students: [],
    total: 0,

  },
  getters: {
    students: state => state.students,
    total: state => state.total,
  },
  mutations: {
    SET_STUDENTS: (state, students) => { state.students = students },
    SET_TOTAL: (state, total) => { state.total = total },
  },
  actions: {

    async getStudens({ commit }, params) {
      try {
        const response = await axios.get('uni/students/list', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const { total } = data.data
          commit('SET_STUDENTS', records)
          commit('SET_TOTAL', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async importStudents({ commit }, params) {
      try {
        const response = await axios.post('uni/students/import', { params })
        const { data } = response
        return {
          code: data.code,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async importClassStudents({ commit }, params) {
      try {
        const response = await axios.post('uni/students/import_student_class', params)
        const { data } = response
        return {
          code: data.code,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
    },

  },
}
